import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Business Case"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Open APIs have become a powerful catalyst for digital transformation because they offer a rapid proliferation of capabilities and therefore contribute to the realization of business value chains that deliver experiences, processes, or data to where they are needed. `}</p>
    <p>{`At enableHR, we do HR really well. It’s all we do. We believe that a single software system that tries to do everything, to cover every part of a business, might do most things adequately, but won’t do all of them really well. That’s why we stick to making our HR software the most powerful and flexible available. `}</p>
    <p>{`While our clients love us for our HR capabilities, they also run other functions such as Payroll and Time & Attendance. We don’t do payroll. We don’t do time and attendance. We do HR.`}</p>
    <p>{`For our clients it is important that enableHR, as the best-in-class HR software for Australian and New Zealand businesses, can work well with the other systems they are using.  `}</p>
    <p>{`We believe the best approach for businesses is to use a number of best-in-class systems, each extremely good at what it does, and seamlessly integrate them using API to share data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      